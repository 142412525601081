import React, { FC, useContext, useEffect } from 'react'
import Text from '../text'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import { LanguageEnum } from '@services/constants'
import Breakpoints from '@utils/breakpoints'
type classes = {
	banner: string
}

export const classes: classes = makeClasses({
	banner: {
		backgroundColor: Colors.lightYellow,
		height: '40px',
		fontSize: '13px',
		textAlign: 'center',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '15px 20px',
		[Breakpoints.maxWidth('sm')]: {
			fontSize: '11px',
			textAlign: 'start'
		}
	}
})

const InformationBanner: FC = () => {
	const { pageData, language } = useContext<AppContextProps>(AppStateContext)

	useEffect(() => {
		const linksContainer = document.getElementsByClassName('link-redirect')
		Array.prototype.forEach.call(linksContainer, (container: HTMLElement) => {
			const links = container.getElementsByTagName('a')
			Array.prototype.forEach.call(links, (link) => {
				link.setAttribute('target', '_blank')
			})
		})
	}, [language])

	const shouldInformationDisplay = () => {
		return (
			pageData.assets?.homepage_charter_information &&
			pageData.assets?.homepage_charter_information?.trim() !== '' &&
			language === LanguageEnum.EN
		)
	}

	return (
		<>
			{shouldInformationDisplay() && (
				<div className={joinClasses([classes.banner, 'link-redirect'])}>
					<Text content={pageData.assets?.homepage_charter_information} />
				</div>
			)}
		</>
	)
}

export default InformationBanner
