import Breakpoints from '@utils/breakpoints'
import { Colors } from '@utils/css-variables'
import { makeClasses } from '@utils/styles'

export type filterProps = {
	name: string
	title: string
	options: any[]
	selectedOptions: any[]
	onHandleFilter: (e: React.ChangeEvent<HTMLInputElement>, name: string) => void
}

type ClassType = {
	outlineButton: string
	buttonActive: string
	dropdown: string
	dropdownActive: string
	checkbox: string
	checkboxContainer: string
	checkboxInputs: string
	label: string
}

export const classes: ClassType = makeClasses({
	outlineButton: {
		background: 'none',
		border: `1px solid ${Colors.secondary}`,
		color: Colors.secondary,
		padding: '15px 30px',
		fontSize: '16px',
		cursor: 'pointer',
		borderRadius: '23px',
		transition: 'all 0.35s ease',
		fontWeight: 500,
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
		gap: '5px',
		':hover': {
			color: Colors.white,
			borderColor: Colors.darkBlue2,
			background: Colors.darkBlue2
		},
		[Breakpoints.maxWidth('sm')]: {
			padding: '10px 20px',
			fontSize: '13px'
		}
	},
	buttonActive: {
		color: Colors.white,
		borderColor: Colors.darkBlue2,
		background: Colors.darkBlue2
	},

	dropdown: {
		display: 'none',
		marginTop: '0px',
		padding: '20px',
		fontSize: '16px',
		position: 'absolute',
		backgroundColor: '#fff',
		minWidth: '220px',
		boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
		zIndex: '1',
		borderRadius: '3px'
	},
	dropdownActive: {
		display: 'block !important'
	},
	checkbox: {
		margin: '10px 0 0',
		border: '0',
		padding: '0',
		clear: 'both'
	},
	checkboxContainer: {
		marginBottom: '15px',
		'& input:checked ~ label:after': {
			opacity: '.99'
		}
	},
	checkboxInputs: {
		position: 'absolute',
		verticalAlign: 'baseline',
		clip: 'rect(1px, 1px, 1px, 1px)'
	},
	label: {
		position: 'relative',
		paddingLeft: '27px',
		fontWeight: 'normal',
		cursor: 'pointer',
		lineHeight: '17px',
		margin: '5px 0',
		':before': {
			content: "''",
			position: 'absolute',
			top: '0',
			left: '0',
			width: '16px',
			height: '16px',
			border: '1px solid #a1a1a1',
			background: '#fff',
			outline: 'none !important'
		},
		':after': {
			content: '" "',
			position: 'absolute',
			top: '4px',
			left: '4px',
			width: '10px',
			height: '10px',
			background: '#0054A6',
			outline: 'none',
			opacity: '0',
			MsFilter: 'alpha(opacity=0)',
			filter: 'alpha(opacity=0)',
			transition: 'all .15s ease-out'
		}
	}
})
