import React, { FC, useRef, useState } from 'react'
import { joinClasses } from '@utils/styles'
import { ChevronDown } from 'react-bootstrap-icons'
import * as pageUtils from '@pages/results-requests/__filterSelect.utils'
import OutsideClickHandler from 'react-outside-click-handler'

const FilterSelect: FC<pageUtils.filterProps> = ({
	name,
	title,
	options,
	selectedOptions,
	onHandleFilter
}) => {
	const [showDropdown, setShowDropdown] = useState<boolean>(false)

	const filterRef = useRef<HTMLFormElement>(null)

	return (
		<>
			<OutsideClickHandler onOutsideClick={() => setShowDropdown(false)}>
				<button
					onClick={() => setShowDropdown(!showDropdown)}
					className={joinClasses([
						pageUtils.classes.outlineButton,
						showDropdown ? pageUtils.classes.buttonActive : ''
					])}
				>
					{title}
					{''}
					<ChevronDown />
				</button>

				<div
					className={joinClasses([
						pageUtils.classes.dropdown,
						showDropdown ? pageUtils.classes.dropdownActive : ''
					])}
				>
					<form ref={filterRef}>
						<fieldset className={pageUtils.classes.checkbox}>
							{options?.map((subTheme, index) => (
								<div
									key={`dropdown-${subTheme.name}`}
									className={pageUtils.classes.checkboxContainer}
								>
									<input
										onChange={(e) => onHandleFilter(e, subTheme.name)}
										type="checkbox"
										id={`${name}-${index}`}
										className={pageUtils.classes.checkboxInputs}
										checked={selectedOptions.includes(subTheme.name)}
									/>
									<label
										className={pageUtils.classes.label}
										htmlFor={`${name}-${index}`}
									>
										{subTheme.title}
									</label>
								</div>
							))}
						</fieldset>
					</form>
				</div>
			</OutsideClickHandler>
		</>
	)
}

export default FilterSelect
