import { chevronBlueIcon, chevronDoubleBlueIcon } from '@images/icons'
import Breakpoints from '@utils/breakpoints'
import { Colors } from '@utils/css-variables'
import { makeClasses } from '@utils/styles'

type ClasssType = {
	section: string
	resultSection: string
	resultShowTotalAndCount: string
	pagination: string
	pager: string
	simple: string
	previous: string
	currentPage: string
	double: string
	hidde: string
	filtersSection: string
	filterSectionActive: string
	sectionNoRequestFound: string
}

export const classes: ClasssType = makeClasses({
	section: {
		background: Colors.white,
		padding: '40px',
		margin: '0px',
		[Breakpoints.maxWidth('md')]: {
			padding: '35px 25px'
		},
		[Breakpoints.maxWidth('xs')]: {
			padding: '25px 15px'
		}
	},
	resultSection: {
		background: Colors.ligthGrey2,

		padding: '10px',
		margin: '40px',

		[Breakpoints.maxWidth('md')]: {
			padding: '35px 25px',
			margin: '35px 20px'
		},
		[Breakpoints.maxWidth('sm')]: {
			margin: '35px 0'
		},
		[Breakpoints.maxWidth('xs')]: {
			padding: '25px 15px',
			margin: '25px 0'
		}
	},
	resultShowTotalAndCount: {
		borderBottom: `1px solid ${Colors.greyAlto}`,
		padding: '0 0 30px',
		marginBottom: '20px',
		fontWeight: 600
	},
	pagination: {
		paddingLeft: 0
	},
	pager: {
		cursor: 'pointer',
		display: 'inline-block',
		width: '24px',
		height: '24px',
		lineHeight: '24px',
		textAlign: 'center',
		fontFamily: '"Roboto", Arial, Helvetica, sans-serif',
		fontWeight: 700,
		verticalAlign: 'middle',
		textDecoration: 'none',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
		borderRadius: '50%',
		':visited': {
			color: Colors.lightBlack
		},
		':link': {
			color: Colors.lightBlack
		},
		':hover': {
			backgroundColor: Colors.darkWhite
		}
	},
	simple: {
		backgroundImage: `url(${chevronBlueIcon})`
	},
	previous: {
		transform: 'scaleX(-1)'
	},
	currentPage: {
		color: Colors.secondary + ' !important',
		backgroundColor: Colors.greyIron
	},
	double: {
		backgroundImage: `url(${chevronDoubleBlueIcon}) !important`
	},
	hidde: {
		display: 'none'
	},
	filtersSection: {
		display: 'flex',
		transition: 'all 0.3s ease',
		visibility: 'hidden',
		opacity: 0,
		marginBottom: '-1rem',
		gap: '10px'
	},
	filterSectionActive: {
		opacity: '1 !important',
		visibility: 'visible !important',
		marginTop: '1rem !important',
		marginBottom: '0rem !important'
	},
	sectionNoRequestFound: {
		'& h3': {
			margin: '0px 0 15px '
		}
	}
})
