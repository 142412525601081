import React, { FC, useContext } from 'react'
import { chevronRightIcon } from '@images/icons'
import Breakpoints from '@utils/breakpoints'
import { Icon } from '@components/ui/Icon'
import { makeClasses } from '@utils/styles'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import Link from '@components/ui/link'
import { formatStrapiText } from '@utils/methods'
import { Colors } from '@utils/css-variables'
import * as pageRequestDetailsUtils from '@pages/auth/requests/__[id].utils'
import config from '@utils/config'

const classes = makeClasses({
	root: {
		background: '#222d65',
		padding: '15px 20px',
		[Breakpoints.minWidth('sm')]: {
			padding: '15px 50px'
		}
	},
	list: {
		margin: 0,
		listStyle: 'none',
		paddingLeft: 0
	},
	item: {
		display: 'inline-flex',
		alignItems: 'center',
		color: Colors.backgroundConnected,
		fontSize: '13px',
		paddingLeft: 0,
		marginBottom: 0
	},
	link: {
		color: `${Colors.lightGrey6} !important`,
		fontWeight: 500,
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline'
		}
	},
	icon: {
		display: 'inline-flex',
		alignItems: 'center',
		margin: '0 8px'
	},
	img: {
		width: '15px',
		height: '15px'
	}
})

const Breadcrumbs: FC = () => {
	const {
		pageData: { pages, requests },
		userRequest,
		currentArticle
	} = useContext(AppStateContext)

	const getKeyList = (pathname?: string): string[] => {
		if (!pathname) {
			return []
		}

		const chunks: string[] = []
		let chunk: string = ''

		for (const item of pathname.split('/')) {
			if (item.length) {
				chunk += '/' + item
				chunks.push(chunk)
			}
		}

		return chunks
	}

	const translateRoute = (path: string): string => {
		if (path?.match(/\/auth\/requests\/((\w+)-)+\w+/) && userRequest) {
			return `${userRequest.code} - ${pageRequestDetailsUtils.getRequestTitle(
				requests,
				userRequest
			)}`
		}

		if (
			path?.match(config.articles.detailsPathMatch) &&
			path.match(config.articles.baseUrlMatch) === null &&
			currentArticle
		) {
			return formatStrapiText(currentArticle.title)
		}

		const dictionary = {
			'/auth': formatStrapiText(pages?.home?.title),
			'/auth/requests/create': formatStrapiText(
				pages?.['create-request']?.title
			),
			'/auth/requests': formatStrapiText(pages?.['my-requests']?.title),
			'/online-services': formatStrapiText(pages?.['online-services'].title),
			'/faq': formatStrapiText(pages?.['faq']?.title),
			'/auth/profile': formatStrapiText(pages?.['profile']?.title),
			'/articles': formatStrapiText(pages?.['articles']?.title),
			'/results-requests': formatStrapiText(pages?.['results-requests']?.title)
		}

		return dictionary[path]
	}

	return (
		<AppStateContext.Consumer>
			{({ pathname }) => {
				// pathname == '/faq' || pathname == '/online-services'

				return (
					<div className={classes.root}>
						<ul className={classes.list}>
							{!!pathname &&
								getKeyList(pathname).map((key: string, index: number) => {
									const isLast = index === getKeyList(pathname).length - 1
									const isFirst = index === 0

									return (
										<li className={classes.item} key={key}>
											{isFirst && (
												<>
													<Link to={'/'} className={classes.link}>
														{formatStrapiText(pages?.home?.title)}
													</Link>
													<div className={classes.icon}>
														<Icon
															src={chevronRightIcon}
															className={classes.img}
														/>
													</div>
												</>
											)}
											{!isLast && key !== '/auth' && (
												<>
													<Link to={key} className={classes.link}>
														{translateRoute(key)}
													</Link>
													<div className={classes.icon}>
														<Icon
															src={chevronRightIcon}
															className={classes.img}
														/>
													</div>
												</>
											)}
											{isLast && translateRoute(key)}
										</li>
									)
								})}
						</ul>
					</div>
				)
			}}
		</AppStateContext.Consumer>
	)
}

export default Breadcrumbs
