import React, { FC, ReactElement } from 'react'

import Breadcrumbs from '../breadcrumbs'
import Breakpoints from '@utils/breakpoints'
import Link from '@components/ui/link'
import { Icon } from '@components/ui/Icon'
import { closeWhiteIcon } from '@images/icons'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import InformationBanner from '../informationBanner'

type Props = {
	title: string | ReactElement
	subTitle?: string
	showBreadcrumbs?: boolean
	link?: string
	classRoot?: string
	showCharterInfo?: boolean
}

type ClassType = {
	root: string
	title: string
	link: string
	icon: string
	subTitle: string
}

const classes: ClassType = makeClasses({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		background: Colors.primary,
		padding: '20px 20px 30px',
		[Breakpoints.minWidth('sm')]: {
			padding: '40px 50px 50px'
		}
	},
	title: {
		padding: 0,
		margin: 0,
		color: Colors.white,
		fontSize: '36px',
		fontWeight: 500,
		[Breakpoints.maxWidth('lg')]: {
			fontSize: '28px'
		},
		[Breakpoints.maxWidth('md')]: {
			fontSize: '22px'
		},
		width: '100%'
	},
	link: {
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		border: `1px solid ${Colors.white}`,
		borderRadius: '50%',
		maxWidth: '48px',
		width: '100%',
		height: '48px'
	},
	icon: {
		width: '30px',
		height: '30px'
	},
	subTitle: {
		display: 'block',
		fontSize: '18px',
		lineHeight: '26px',
		marginTop: '5px',
		fontWeight: 400,
		[Breakpoints.maxWidth('md')]: {
			fontSize: '14px',
			lineHeight: '18px'
		},
		[Breakpoints.maxWidth('sm')]: {
			paddingRight: '5px'
		}
	}
})

const SectionTitle: FC<Props> = ({
	title,
	showBreadcrumbs = true,
	link,
	subTitle,
	classRoot,
	showCharterInfo = true
}) => {
	const displayLinkIcon = () => (
		<Link to={link || ''} className={classes.link}>
			<Icon src={closeWhiteIcon} className={classes.icon} />
		</Link>
	)

	const subtitleSection = () => (
		<span className={classes.subTitle}>{subTitle}</span>
	)

	return (
		<>
			{showCharterInfo && <InformationBanner />}
			{showBreadcrumbs && <Breadcrumbs />}
			<div className={joinClasses([classes.root, classRoot || ''])}>
				<h1 className={classes.title}>
					{title}
					{subTitle && subtitleSection()}
				</h1>
				{link && displayLinkIcon()}
			</div>
		</>
	)
}

export default SectionTitle
