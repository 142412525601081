import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import { PageTitle } from '@components/configs/PageTitle'
import SectionTitle from '@components/ui/sectionTitle'
import * as pageUtils from '@pages/results-requests/__index.utils'
import SearchRequests from '@components/ui/requestSearch/search'
import Loader from '@components/ui/loader'
import ReactPaginate from '@components/ui/pagination'
import { joinClasses } from '@utils/styles'
import { useAppDispatch, useAppSelector } from '@services/store'
import { getSearchText } from '@services/store/articles/selector'
import { checkIfIsPageArticles } from '@services/store/articles'
import { Request } from 'typings/shared'
import RequestListCard from '@components/ui/requestSearch/RequestListCard'
import FilterSelect from './filterSelect'
import Text from '@components/ui/text'

type stateType = {
	searchText?: string
}

const RequestsResultPage: FC = () => {
	const { pageData } = useContext<AppContextProps>(AppStateContext)
	const searchTextStore: string = useAppSelector(getSearchText)
	const dispatch = useAppDispatch()
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [requests, setRequests] = useState<Request[]>([])
	const [displayedRequests, setDisplayedRequests] = useState<Request[]>([])

	const [filterCategList, setFilterCategList] = useState<string[]>([])
	const [filterTypeList, setFilterTypeList] = useState<string[]>([])
	const [searchText, setSearchText] = useState<string>(searchTextStore ?? '')

	const Items = ({ currentItems }) => {
		return (
			<div className="items">
				{!!currentItems &&
					currentItems.map((request: Request, key: number) => (
						<RequestListCard
							request={request}
							index={key}
							id={key}
							requestThemes={pageData?.collections?.requestThemes}
						/>
					))}
			</div>
		)
	}

	useEffect(() => {
		dispatch(checkIfIsPageArticles(true))

		return () => {
			dispatch(checkIfIsPageArticles(false))
		}
	}, [])

	const handleCategFilter = (
		e: React.ChangeEvent<HTMLInputElement>,
		name: string
	) => {
		if (e.target.checked && !filterCategList.includes(name)) {
			const tempList = [...filterCategList, name]
			setFilterCategList(tempList)
		}

		if (!e.target.checked && filterCategList.includes(name)) {
			const tempList = [...filterCategList].filter((el) => el !== name)
			setFilterCategList(tempList)
		}
	}

	const handleTypeFilter = (
		e: React.ChangeEvent<HTMLInputElement>,
		name: string
	) => {
		if (e.target.checked && !filterTypeList.includes(name)) {
			const tempList = [...filterTypeList, name]
			setFilterTypeList(tempList)
		}

		if (!e.target.checked && filterTypeList.includes(name)) {
			const tempList = [...filterTypeList].filter((el) => el !== name)
			setFilterTypeList(tempList)
		}
	}

	useEffect(() => {
		filterRequests(requests, filterCategList, filterTypeList)
	}, [requests, filterCategList, filterTypeList])

	useEffect(() => {
		setFilterTypeList([])
		setFilterCategList([])
	}, [searchTextStore])

	const filterRequests = (
		requests: Request[],
		filterCategList: string[],
		filterTypeList: string[]
	) => {
		const reqCategList = !!filterCategList.length
			? requests.filter(
					(request) =>
						!!request.subThemes.find((subTheme) =>
							filterCategList.includes(subTheme)
						)
			  )
			: requests

		const reqTypeList = !!filterTypeList.length
			? reqCategList.filter((request) =>
					filterTypeList.includes(getRequestGroupName(request.requestId))
			  )
			: reqCategList

		setDisplayedRequests(reqTypeList)
	}

	const getRequestGroupName = (requestId: string) => {
		let requestGroup = ''
		pageData?.collections?.requestThemes.forEach((group) => {
			if (group.requests.find((req) => req.requestId == requestId)) {
				requestGroup = group.name
			}
		})

		return requestGroup
	}

	const isSubThemeExist = (name: string) => {
		let found: boolean = false
		requests.forEach((req) => {
			if (req.subThemes.includes(name)) {
				found = true
			}
		})
		return found
	}

	const getSubThemes = () => {
		let themes: any[] = []
		pageData?.collections?.requestThemes.forEach((group) => {
			group?.requestSubThemes.forEach((subTheme) => {
				if (isSubThemeExist(subTheme.name)) {
					if (!themes.some((thm) => thm.name == subTheme.name)) {
						themes.push(subTheme)
					}
				}
			})
		})

		return themes
	}

	const getRequestGroups = () => {
		let requestGroups: any[] = []
		pageData?.collections?.requestThemes.forEach((group) => {
			group.requests.forEach((request) => {
				if (requests.find((req) => req.requestId == request.requestId)) {
					if (!requestGroups.some((reqGrp) => reqGrp.name == group.name)) {
						requestGroups.push(group)
					}
				}
			})
		})

		return requestGroups
	}

	const PaginatedItems = ({ itemsPerPage }) => {
		// We start with an empty list of items.
		const [currentItems, setProcessingItems] = useState<any>(null)
		const [pageCount, setPageCount] = useState(0)
		// Here we use item offsets; we could also use page offsets
		// following the API or data you're working with.
		const [itemOffset, setItemOffset] = useState(0)

		useEffect(() => {
			// Fetch items from another resources.
			const endOffset = itemOffset + itemsPerPage

			setProcessingItems(displayedRequests.slice(itemOffset, endOffset))
			setPageCount(Math.ceil(displayedRequests.length / itemsPerPage))
		}, [itemOffset, itemsPerPage, displayedRequests.length])

		// Invoke when user clicks to request another page.
		const handlePageClick = (event) => {
			const newOffset =
				(event.selected * itemsPerPage) % displayedRequests.length

			setItemOffset(newOffset)

			const element = document.getElementById('listPaginate')
			const y = element!.getBoundingClientRect().top + window.pageYOffset + -80

			window.scrollTo({ top: y, behavior: 'smooth' })
		}

		return (
			<>
				<div className={pageUtils.classes.resultShowTotalAndCount}>
					{itemOffset + 1}
					{` ${pageData?.assets?.articles_to} `}
					{itemOffset + itemsPerPage > displayedRequests.length
						? displayedRequests.length
						: itemOffset + itemsPerPage}
					{` ${pageData?.assets?.articles_of} ${displayedRequests.length} ${pageData?.assets?.articles_results}`}
				</div>
				<Items currentItems={currentItems} />
				<ReactPaginate
					hide={displayedRequests.length <= itemsPerPage}
					onPageChange={handlePageClick}
					pageRangeDisplayed={3}
					marginPagesDisplayed={2}
					pageCount={pageCount}
					previousLabel="&nbsp;"
					nextLabel="&nbsp;"
					pageClassName={pageUtils.classes.pager}
					pageLinkClassName={pageUtils.classes.pager}
					previousClassName={pageUtils.classes.pager}
					previousLinkClassName={joinClasses([
						pageUtils.classes.pager,
						pageUtils.classes.simple,
						pageUtils.classes.previous
					])}
					nextClassName={pageUtils.classes.pager}
					nextLinkClassName={joinClasses([
						pageUtils.classes.pager,
						pageUtils.classes.simple
					])}
					breakLabel="..."
					breakClassName={pageUtils.classes.pager}
					breakLinkClassName={pageUtils.classes.pager}
					containerClassName={pageUtils.classes.pagination}
					activeClassName={pageUtils.classes.currentPage}
					firstItemLabel="&nbsp;"
					lastItemLabel="&nbsp;"
					firstItemClassName={pageUtils.classes.pager}
					lastItemClassName={pageUtils.classes.pager}
					firstItemLinkClassName={joinClasses([
						pageUtils.classes.pager,
						pageUtils.classes.double,
						pageUtils.classes.previous
					])}
					lastItemLinkClassName={joinClasses([
						pageUtils.classes.pager,
						pageUtils.classes.double
					])}
					disabledClassName={pageUtils.classes.hidde}
				/>
			</>
		)
	}

	return (
		<>
			<PageTitle title={pageData.title} />
			<SectionTitle title={pageData.title} />

			<section className={pageUtils.classes.section}>
				<SearchRequests
					isLoading={isLoading}
					onSetIsLoading={setIsLoading}
					onSetRequests={setRequests}
					onNavigateToRequests={() => {}}
					searchText={searchText}
					setSearchText={setSearchText}
				/>

				<div
					className={joinClasses([
						pageUtils.classes.filtersSection,
						requests.length ? pageUtils.classes.filterSectionActive : ''
					])}
				>
					<FilterSelect
						name="categories"
						title={pageData.assets?.page_create_request_filter_by_category}
						options={getSubThemes()}
						selectedOptions={filterCategList}
						onHandleFilter={handleCategFilter}
					/>
					<FilterSelect
						name="types"
						title={pageData.assets?.page_resultsRequests_filter_by_type}
						options={getRequestGroups()}
						selectedOptions={filterTypeList}
						onHandleFilter={handleTypeFilter}
					/>
				</div>
			</section>

			<section
				id={'listPaginate'}
				className={joinClasses([
					pageUtils.classes.section,
					pageUtils.classes.resultSection
				])}
			>
				{isLoading && <Loader text={pageData?.assets?.loading} />}
				{!isLoading && displayedRequests.length === 0 && (
					<div className={pageUtils.classes.sectionNoRequestFound}>
						<Text
							content={pageData?.assets?.page_resultsRequests_no_request_found}
						/>
					</div>
				)}
				{!isLoading && displayedRequests.length > 0 && (
					<>
						<PaginatedItems itemsPerPage={10} />
					</>
				)}
			</section>
		</>
	)
}

export default RequestsResultPage
